.input {
    display: inline-block;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 10px 20px;
    outline: none;
    border: 1px solid #b7b7b7;
    -webkit-border-radius: 20px 3px;
    border-radius: 20px 3px;
    font: normal 16px/normal "Times New Roman", Times, serif;
    color: rgba(0, 142, 198, 1);
    text-decoration: none;
    text-align: left;
    text-indent: 0;
    -o-text-overflow: clip;
    text-overflow: clip;
    letter-spacing: 0;
    white-space: normal;
    word-spacing: 0;
    background: rgba(252, 252, 252, 1);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.66);
    -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
}

textarea.input {
    width: calc(100% - 40px);
    height: 10vh;
    resize: none;
}


/* input type =checkbox */

input[type="checkbox"].input {
    width: 30px;
    height: 30px;
}

.select {
    width: 80%;
    position: relative;
    display: inline-block;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 10px 20px;
    outline: none;
    border: 1px solid #b7b7b7;
    -webkit-border-radius: 20px 3px;
    border-radius: 20px 3px;
    font: normal 16px/normal "Times New Roman", Times, serif;
    color: rgba(0, 142, 198, 1);
    text-decoration: none;
    text-align: left;
    text-indent: 0;
    -o-text-overflow: clip;
    text-overflow: clip;
    letter-spacing: 0;
    white-space: normal;
    word-spacing: 0;
    background: rgba(252, 252, 252, 1);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.66);
    -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
}

.select__head {
    width: 100%;
}

.select__value {
    text-align: center;
    font-weight: 900;
}

.select__body {
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    outline: none;
    border-top: 1px solid #b7b7b7;
    /* border: 1px solid #b7b7b7; */
    animation: select__body 0.4s ease;
}

.select__item {
    padding-top: 10px;
    text-align: center;
    display: block;
    /* width: 100%; */
    padding: 10px 20px;
    outline: none;
    border: none;
    background: rgba(252, 252, 252, 1);
    /* border-bottom: #b7b7b7 1px solid; */
    cursor: pointer;
    /* border: 1px solid #b7b7b7; */
}

.select__new {
    /* border-top: #ff0000; */
    font-weight: 900;
    color: blue;
    text-decoration: double underline;
}

.textarea {
    height: 10vh;
    resize: none;
}

.doubleForm {
    width: 80%;
}

.instructionElement {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #b7b7b7;
    background: rgb(229, 229, 229);
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.error {
    background: rgba(255, 0, 0, 0.5);
}

.errorInst {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    background: rgba(255, 255, 255, 0.5);
    z-index: 999;
}

.instructionElement label {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap
}


/* .instructionElement checkbox horisonal align center */

.instructionElement input[type="checkbox"] {
    margin: 0 auto;
}

.selectInst {
    width: 100%;
    min-height: 250px;
    margin: 0 auto;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 10px 10px;
    outline: none;
    border: 1px solid #b7b7b7;
    border-radius: 20px 3px;
    font: normal 16px/normal "Times New Roman", Times, serif;
    color: rgba(0, 142, 198, 1);
    text-decoration: none;
    text-align: left;
    text-indent: 0;
    text-overflow: clip;
    letter-spacing: 0;
    white-space: normal;
    word-spacing: 0;
    background: rgb(229, 229, 229);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.66);
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
}

.selectSearch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dropdown {
    position: absolute;
    z-index: 1;
    background-color: white;
    min-width: 45%;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown li {
    list-style-type: none;
    padding: 5px 10px;
    cursor: pointer;
}

.dropdown li:hover {
    background-color: #f0f0f0;
}

.selectInst ul {
    list-style: decimal
}

.selectedLi {
    padding: 5px;
    cursor: pointer;
}

.selectMain {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

@keyframes select__body {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}