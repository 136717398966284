.journal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
}

.journal_part {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    margin: 1vh 0;
}

.journal_part__form {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid #b7b7b7;
}

.journal_part__item {
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
    border-bottom: 1px solid #000;
    padding: 1vh;
}

.journal_part__item_element {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1vh;
}

.journal_part__item_element>* {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
}

.journal_part__item_element>input,
.journal_part__item_element>textarea {
    width: 80%;
}

.boolean_button {
    width: 88%;
    align-items: flex-start;
}

.bool {
    grid-template-columns: 20% 600px;
}

.double {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error {
    background: #f28a8a;
    border: 1px solid #ff0000;
}

.journal_part_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    margin: 1vh 0;
}

.journal_part_button button {
    margin: 0 2vw;
    margin-bottom: 3vh;
}

.element_item_title {
    grid-column: 1/3;
    /* font-weight: 900; */
    padding: 0;
}

.borderbottom {
    border: 1px solid rgb(191, 191, 191);
    border-bottom: none;
}

.bordertop {
    border: 1px solid rgb(191, 191, 191);
    border-top: none;
}

.yellow {
    background-color: #d4d64f;
}