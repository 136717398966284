.button {
    display: inline-block;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    cursor: pointer;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 10px 20px;
    outline: none;
    border: 2px solid #018dc4;
    -webkit-border-radius: 20px 3px;
    border-radius: 20px 3px;
    text-decoration: none;
    text-align: left;
    text-indent: 0;
    -o-text-overflow: clip;
    text-overflow: clip;
    letter-spacing: 0;
    white-space: normal;
    word-spacing: 0;
    background: #0199d9;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
    text-shadow: -1px -1px 0 rgba(15, 73, 168, 0.66);
    -webkit-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
    -moz-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
}

.red {
    display: inline-block;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid rgba(231, 76, 60, 1);
    -webkit-border-radius: 20px 3px;
    border-radius: 20px 3px;
    -o-text-overflow: clip;
    text-overflow: clip;
    background: #e74c3c;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
    -moz-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
}

.button,
.red {
    min-width: 50px;
    text-align: center;
    font: normal normal bold 16px/normal "Times New Roman", Times, serif;
    color: rgba(255, 255, 255, 1);
}

.green {
    background: #24852b;
    border: 1px solid rgba(36, 133, 43, 1);
}

.button:hover {
    background: #0175a2;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
    text-shadow: -1px -1px 0 rgba(15, 73, 168, 0.66);
}

.red:hover {
    background: #c0392b;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.disabled {
    opacity: 0.5;
    cursor: no-drop;
}