.addReference {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh 5vw;
}

.addReference>div {
    margin-bottom: 2vh;
}