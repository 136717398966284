.userForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.userForm_item {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0;
}

.userForm_item>div {
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
}