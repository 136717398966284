.searchPanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    /* width: 100%; */
    /* background-color: #f5f5f5; */
    margin-top: 2vh;
    border: 1px solid #b7b7b7;
    padding: 10px;
}

.searchPanel>div {
    min-width: 250px;
}

.searchPanel input {
    margin: 2vw
}

table {
    border: 1px solid #b7b7b7;
    border-collapse: collapse;
    width: 90%;
    margin: 0 auto
}

.table td,
.table th {
    padding: 0.5vw;
    border: 1px solid #b7b7b7;
    border-collapse: collapse !important;
}

th.min {
    max-width: 4em;
}

td {
    text-align: center;
}

.choise {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2vh;
}

.choise div {
    border: 1px solid black;
    min-width: 20vw;
    text-align: center;
}

.notThis {
    opacity: 0.3;
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.pagination div {
    padding: 5px;
    text-align: center;
    cursor: pointer;
}

.active {
    border-bottom: 1px solid black;
    font-weight: bold;
    font-size: 2vh;
    cursor: text
}