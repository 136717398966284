.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0 4vw;
}

.login div {
    margin: 1vh 0;
}