.sja {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    background-color: #f5f5f5;
    padding: 0 2vw;
    /* font-size: 14px; */
}

.title {
    font-size: 3vh;
    font-weight: 600;
    color: #000;
    text-align: center;
    margin: 2vh 0;
}

.top_form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
}

.top_form_item {
    /* padding: 1vh 0; */
    border: 1px solid rgb(183, 183, 183);
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: auto;
}

.label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: 1vw;
}

.input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    padding: 1vh 1vw;
}

.input input {
    width: 100%;
}

.subtitle {
    width: 100%;
    background-color: rgb(184, 184, 184);
    text-align: center;
    padding: 5px 0;
}

.form {
    display: grid;
    grid-template-columns: 5% 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    margin: 5vh 0 0 0;
}

.form_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(130, 130, 130);
    height: auto;
}

.form_title {
    background-color: rgb(184, 184, 184);
}

.risk_title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0;
}

.risk {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
}

.risk>div {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(130, 130, 130);
}

.form_bottom {
    display: grid;
    grid-template-columns: 1fr 1fr 20% 1fr;
    width: 100%;
    margin: 5vh 0;
}

.buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    /* margin: 0 0 0 1vw; */
    margin-bottom: 2vh;
}

.form_instrument {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: none;
}

.form_instrument span {
    text-align: center;
    font-size: 2vh;
    font-weight: 600;
    border: 1px solid rgb(130, 130, 130);
    width: 2vh;
    height: 2vh;
    cursor: pointer;
    margin: 1vh 0.5vw;
}

.form_input {
    font-size: 14px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    width: calc(100% - 6px - 1vw);
    height: 100%;
    border: none;
    padding: 0 0.5vw;
    text-align: center;
}

.risk_green {
    background-color: rgb(0, 255, 0);
}

.risk_yellow {
    background-color: rgb(255, 255, 0);
}

.risk_red {
    background-color: rgb(255, 0, 0);
}

.form_bottom .form_item input {
    border-radius: 0;
    border: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    border: 1px solid rgb(130, 130, 130);
}

.form_item textarea {
    border: 1px solid rgb(130, 130, 130);
    max-height: 25vh;
}

.error {
    background-color: #f28a8a;
    border: 1px solid #ff0000;
}

.errorInput * {
    background-color: #f28a8a;
    border: 1px solid #ff0000;
}