textarea {
    background: #ffffff;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ededed;
    font-size: 2vh;
    /* line-height: 1.5; */
    /* padding: 8px 0; */
    resize: none;
    transition: border-color 150ms ease;
    width: calc(100% - 6px);
}