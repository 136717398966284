.form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 2vh;
}

.form>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.button {
    grid-column: 1/3;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 2vh;
}

.error {
    background: #f28a8a;
    border: 1px solid #ff0000;
}