.navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    padding: 0 20px;
    box-sizing: border-box;
}

.logo {
    width: 70px;
    height: 70px;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}

.online {
    border: 4px solid green;
    border-radius: 50%;
}

.offline {
    border: 4px solid red;
    border-radius: 50%;
}