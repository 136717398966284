.container {
    padding: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}

.expired {
    background-color: #ffcccc;
    /* Красный фон */
}

.warning {
    background-color: #fff3cd;
    /* Желтый фон */
}

.quarantine {
    background-color: #e0e0e0;
    /* Серый фон */
    text-decoration: line-through;
    /* Перечеркнутый текст */
    color: #6c757d;
    /* Серый текст */
}

.pagination {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.pagination span {
    cursor: pointer;
    margin: 0 5px;
    color: #007bff;
}

.pagination span:hover {
    text-decoration: underline;
}

.pagination>.activePage {
    color: black;
    font-weight: bold;
    pointer-events: none;
}

.soon {
    background-color: rgb(235, 235, 146);
}

.expired {
    background-color: rgb(241, 132, 132);
    color: white;
}

.quarantine {
    background-color: lightgray;
    text-decoration: line-through;
}

.controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filters {
    display: flex;
    align-items: center;
}

.filters>button {
    margin-left: 10px;
    border-radius: 10px;
    border: 1px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #f2f2f2;
    transition: all 0.3s;
}

.filters>button:hover {
    background-color: #5c5c5c;
    color: white;
}