.form {
    width: 80%;
    margin: 30px auto;
}

h1 {
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    margin-bottom: 5vh;
}

.form p {
    font-size: 1.5rem;
    font-weight: 900;
    text-align: center;
}

.button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 5vh;
}