.choise {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    margin: 2vh auto;
}

.choise div {
    font-size: 3vh;
    opacity: 0.3;
    font-weight: 900;
    border: 1px solid black;
    padding: 1vh 1vw;
    min-width: 17vw;
    text-align: center;
    cursor: pointer;
    transition: 0.3s all linear;
}

.choise div.active {
    opacity: 1;
}

.sjaUpload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;
}

.sjaUpload div {
    margin: 0 2vw;
}

.reference {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
    margin: 2vh auto;
}

.reference div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    padding: 1vh 1vw;
}

.reference div button {
    margin: 0 1vw;
}