.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(111, 111, 111, 0.3);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.6px);
    -webkit-backdrop-filter: blur(5.6px);
    border: 1px solid rgba(168, 164, 164, 0.3);
    animation-name: appear;
    animation-duration: 300ms;
}

.modal_dialog {
    width: 100%;
    max-width: 70vw;
    background: white;
    position: relative;
    margin: 0 20px;
    max-height: calc(100vh - 40px);
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 40px 9px / 40px 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: slide-in;
    animation-duration: 0.5s;
}

.modal_header,
.modal_footer {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.modal_header {
    border-bottom: 1px solid #dbdbdb;
    justify-content: space-between;
}

.modal_footer {
    border-top: 1px solid #dbdbdb;
    justify-content: flex-end;
}

.modal_close {
    cursor: pointer;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal_body {
    overflow: auto;
}

.modal_content {
    padding: 1rem;
}

.modal_title {
    width: 100%;
    text-align: center;
    font-size: 2.5vh;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slide-in {
    from {
        transform: translateY(-150px);
    }
    to {
        transform: translateY(0);
    }
}

@media screen and (max-width: 768px) {
    .modal_dialog {
        max-width: 95vw;
        margin: 0;
    }
    .modal_content button {
        width: 100%;
    }
    .modal_close {
        font-size: 4vh;
        font-weight: 900;
    }
}